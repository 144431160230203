import React from "react";
import Header from "../partials/header"
import Footer from "../partials/footer"
import FaqDetail from "../components/FaqDetail";
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const Aboutus = () => {
    return (
        <main>
            <Header/>
            <AruzySeo title={"FAQ"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={"FAQ's"}/>
                            </div>
                            <div className="row justify-content-center">
                                <FaqDetail/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    );
};

export default Aboutus;